html {
	font-size: 16px;
	font-weight: 400;
	line-height: 1.25;
}

body {
	color: #333333;
	font-size: 0.875rem;
	overflow-x: hidden;
}

.clearfix {
	&:before,
	&:after {
		clear: both;
		content: "";
		display: block;
	}
}

.container {
	@extend .clearfix;
}

.container {
	margin-left: auto;
	margin-right: auto;
	max-width: 68.75rem;
	width: 100%;
}

#page-content {
	padding: 0.938rem 0.625rem 3.125rem;
}

#page-content.p-0 {
	padding: 0 0 3.125rem 0;
}

@media (max-width: 768px) {
	body #page-content {
		padding-top: 4.9375rem !important;
	}

	body #page-content.p-0 {
		padding-top: 8rem !important;
	}
}

.planComparator-mobile {
	position: sticky;
	top: 7.8rem;
}

// adding the css here for overriding the styling of BDS component
//****** WARNING: Do not override BDS styling without confirmation of BDS team ******//

.radio-with-checked-icon {
	.radio-icon {
		> .radio-icon-inner-circle {
			@apply bg-interactive-700;
		}
	}
}

//******* END of BDS overriding css **********//
